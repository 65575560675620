import React, { useContext } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslations } from '../../hooks/use-translations'
import SEO from '../../components/seo'
import * as s from '../../pages/news.module.scss'
import NewsItemHeader from '../../components/NewsItemHeader/NewsItemHeader'
import { INewsItemData } from '../../types'
import NewsItemQuote from '../../components/NewsItemQuote/NewsItemQuote'
import NewsItemResult from '../../components/NewsItemResult/NewsItemResult'
import BrandButton from '../../components/BrandButton/BrandButton'
import { GlobalContext } from '../../context/GlobalContext'
import cn from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'

const getData: INewsItemData = {
  header: {
    date: '09.09.2021',
    tag: 'Проекты',
    title:
      'Цифровой контроль строительства для достижения высокого качества реализации проекта',
    description:
      'Как контролировать выполнение работ текущего строительства, сроки и стоимость с помощью решений линейки SIGNAL',
    services: 'SIGNAL DASHBOARD,\nSIGNAL INSPECTION,\nSIGNAL VALUE',
    region: 'Россия, Москва',
    logoName: 'MR group',
  },
  quote: {
    author: 'Александр Обухов',
    position: 'исполнительный директор MR Group',
    text: '«SIGNAL решает задачу качественного и объективного информирования проектной команды по актуальной ситуации на строительной площадке. В данной системе, в режиме реального времени девелопер имеет достоверную информацию в цифровом виде на мобильном устройстве: объём выполненных работ с отображением статуса на BIM модели, плановые и фактически закрытые работы по КС-2, еженедельно обновляемые фотографии 360° со строительной площадки, что позволяет оптимизировать внутренние ресурсы компании».',
  },
  result: {
    text: 'Модуль Dashboard стал единым стандартом отчётности по всем строительным объектам заказчика. Руководство компании на регулярной основе получает точные данные по реальной ситуации на стройке: расчёт объёмов работ производится по BIM-модели. Используя мобильное устройство, в любое время и на любом участке возможно сравнить план и факт реализации проекта. Срок согласования выполненных работ уменьшился в 2 раза, а количество обходов — в 4 раза. «Благодаря использованию решений SIGNAL и среды общих данных BIM 360, не только девелопер, а все участники строительства: подрядчики, проектировщики, инженеры строительного контроля, имеют единую и объективную информацию в цифровой модели в режиме онлайн, на любом мобильном устройстве, что позволяет контролировать статус реализации инвестиционного проекта», - резюмирует Александр Обухов. Компания MR Group видит процесс цифровизации строительных процессов и формирование цифрового двойника здания на этапе эксплуатации ключевым драйвером повышения эффективности и успешного развития девелоперского бизнеса.',
  },
}

const MrgroupPage: React.FC = () => {
  const { locale } = useTranslations()
  const { isDesktop } = useContext(GlobalContext)
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="MR Group"
        description={
          locale === 'en'
            ? 'Цифровой контроль строительства для достижения высокого качества реализации проекта'
            : 'Цифровой контроль строительства для достижения высокого качества реализации проекта'
        }
      />
      <NewsItemHeader
        data={getData.header}
        images={{
          background: images.background.childImageSharp.gatsbyImageData,
          backgroundMobile:
            images.backgroundMobile.childImageSharp.gatsbyImageData,
          logo: images.logo.childImageSharp.gatsbyImageData,
          logoMobile: images.logoMobile.childImageSharp.gatsbyImageData,
        }}
      />
      <NewsItemQuote
        data={getData.quote}
        avatar={images.avatar.childImageSharp.gatsbyImageData}
      />
      <StaticImage
        className={cn(s.imageBlock, s.imageFullWidth)}
        src="../../assets/images/news/2021_09_09_ss_mrgroup/image-1.png"
        alt={`${getData.header.title} 1`}
        title={`${getData.header.title} 1`}
        placeholder="blurred"
        layout="fullWidth"
      />
      <section className={cn(s.section, s.maxContent)}>
        <div className={s.content}>
          <h2 className={s.textTitle}>Компания и ее текущие проекты</h2>
          <p className={s.text}>
            Компания MR Group успешно работает на российском рынке с 2003 года и
            сегодня безусловный лидер в девелопменте жилой и коммерческой
            недвижимости.
          </p>
          <p className={s.text}>
            Портфель компании — это 44 объекта общей площадью 7 миллионов кв. м
            в Москве, Московской области и Сочи.
          </p>
          <p className={s.text}>
            Компания входит в ТОП-3 девелоперов Московского региона и имеет
            множество престижных профессиональных наград. С 2016 года MR Group
            активно использует технологию BIM на всех этапах жизненного цикла
            объекта.
          </p>
          <p className={s.text}>
            Добившись реализации информационных требований заказчика для
            компаний, участвующих в цикле проектирования на её проектах, MR
            Group приступает к реализации всех возможностей применения цифровых
            технологий непосредственно на стройке.
          </p>
        </div>
        <div className={s.content}>
          <h2 className={s.textTitle}>Задачи цифрового развития на стройке</h2>
          <p className={s.text}>
            Не секрет, что строительство — одна из самых консервативных
            индустрий: любые корректировки в значимых процессах всегда сопряжены
            с изменением парадигмы мышления. Компания MR Group находится в
            постоянном развитии: стремится найти, апробировать и внедрить те
            решения, которые помогут компании быть эффективнее и технологичнее.
            Одно из главных слагаемых успеха MR Group – особенный и стабильный
            продукт, где соблюдению высоких архитектурных стандартов, качеству и
            срокам строительства отведена ключевая роль. Это невозможно без
            сквозного внедрения инструментов цифрового информационного
            моделирования, строительства и контроля.
          </p>
          <p className={s.text}>
            «Наша цель — контроль и оптимизация процесса строительства», –
            отмечает Александр Обухов, исполнительный директор MR Group.
            «Застройщик хочет контролировать объёмы и сроки выполнения работ с
            точки зрения соответствия бизнес-плану, основываясь на реальной
            ситуации на строительной площадке».
          </p>
          <StaticImage
            className={s.imageBlock}
            src="../../assets/images/news/2021_09_09_ss_mrgroup/image-2.png"
            alt={`${getData.header.title} 2`}
            title={`${getData.header.title} 2`}
            placeholder="blurred"
          />
          <p className={s.text}>
            Использование BIM-моделей в проектах MR Group должно быть не только
            на стадии проектирования, но также должна происходить их
            актуализация на стадии строительства с последующей трансформацией в
            исполнительную модель. Итоговая задача — получить основу для
            цифровой эксплуатации зданий, объединив всех участников
            проектирования и строительства, на всем протяжении инвестиционного
            проекта в единую экосистему девелопера.
          </p>
          <p className={s.text}>
            Первым шагом для MR Group стала разработка единых стандартов работы
            с цифровой информацией для своих подрядчиков. Следующим ключевым
            этапом стал выбор программного продукта, который, используя
            имеющиеся проектные данные, помог бы процессам на стройке стать
            эффективнее, а контроль сделать прозрачным, объективным и
            оперативным. Компания выбрала SIGNAL.
          </p>
          <p className={s.text}>
            SIGNAL — это набор цифровых продуктов, основанных на использовании
            BIM, которые повышают эффективность управления проектами в
            строительстве: обеспечивают руководителей всех участников
            строительства идентичной, объективной и своевременной информацией об
            объёмах, сроках, стоимости, качестве и рисках строительства для
            принятия управленческих решений.
          </p>
          <p className={s.text}>Продукт состоит из 4-х модулей.</p>
          <div className={s.brandButtons}>
            <BrandButton
              size={!isDesktop ? 'small' : 'medium'}
              title="Dashboard"
              className="mt-2 mb-2"
            />
            <BrandButton
              size={!isDesktop ? 'small' : 'medium'}
              title="Value"
              className="mt-2 mb-2"
            />
            <BrandButton
              size={!isDesktop ? 'small' : 'medium'}
              title="Tools"
              className="mt-2 mb-2"
            />
            <BrandButton
              size={!isDesktop ? 'small' : 'medium'}
              title="Inspection"
              className="mt-2 mb-2"
            />
          </div>
          <p className={`${s.text} mt-8`}>
            Модуль Dashboard – аналитический кабинет заказчика, где любой
            руководитель получает возможность однозначного понимания ситуации на
            строительной площадке, и имеет всегда актуальную и полноценную
            информацию в одном месте для оперативного управления.
          </p>
          <p className={s.text}>
            Модуль Inspection благодаря инструментарию панорамной фотосъёмки
            360° позволяет удалённо мониторить реальную ситуацию на строительной
            площадке, проводить удалённую инспекцию и верифицировать объёмы и
            качество выполненных работ, уменьшая количество совещаний и споров
            на строительной площадке.
          </p>
          <p className={s.text}>
            Модуль Value позволяет обеспечить цифровой документооборот на
            строительной площадке и радикально уменьшить количество бумажных
            документов в процессе строительства: начиная от проверки и
            верификации Рабочей документации с помощью QR-кодов, выдачи Рабочей
            документации «в производство работ», подписанной электронной
            цифровой подписью, до формирования исполнительной документации в
            соответствии с требованиями РФ в электронном виде с привязкой актов,
            схем, заключений, паспортов и сертификатов непосредственно к
            выполненным конструкциям — элементам BIM-модели. Кроме того, в
            модуле Value реализованы возможности формирования электронной Базы
            качества здания (замечаний строительного контроля, требующих учёта и
            устранения), также с привязкой к элементам BIM-модели, гибкое
            резервное копирование среды общих данных на сервера компании и
            другие полезные функции.
          </p>
          <div className="flex justify-between flex-wrap">
            <StaticImage
              className={s.imageBlock}
              src="../../assets/images/news/2021_09_09_ss_mrgroup/image-3.png"
              alt={`${getData.header.title} 3`}
              title={`${getData.header.title} 3`}
              placeholder="blurred"
            />
            <StaticImage
              className={s.imageBlock}
              src="../../assets/images/news/2021_09_09_ss_mrgroup/image-4.png"
              alt={`${getData.header.title} 4`}
              title={`${getData.header.title} 4`}
              placeholder="blurred"
            />
          </div>
          <p className={`${s.text} mt-8`}>
            MR Group совместно со специалистами SIGNAL и партнёрами по внедрению
            реализовали пилотные проекты использования продуктов SIGNAL на таких
            знаковых для компании проектах, как: «Hide», «SLAVA», «Павелецкая
            Сити» (реализуется совместно с Level Group).
          </p>
          <p className={s.text}>
            На этих объектах участники строительства протестировали продукты
            SIGNAL в непосредственной работе на стройке, подтвердили простоту их
            реализации и удобство использования, а главное, увидели повышение
            эффективности производственных процессов, увеличение скорости
            контрольно-проверочных процедур, снижение финансовых и трудовых
            затрат за счёт исключения бумажного документооборота и связанных с
            ним ошибок.
          </p>
          <p className={s.text}>
            «Сотрудники компании SIGNAL учли наши пожелания при внедрении
            продукта, оптимизировали наши бизнес-процессы, помогли провести
            обучение команды и реализовали пилотные проекты в сжатые сроки. С
            2021 года мы планируем масштабировать SIGNAL на все проекты нашей
            компании», - добавляет Александр Обухов.
          </p>
        </div>
      </section>
      <NewsItemResult
        data={getData.result}
        title={`${getData.header.title} 5`}
        image={images.result.childImageSharp.gatsbyImageData}
      />
    </>
  )
}

export default MrgroupPage

const imagesQuery = graphql`
  query {
    avatar: file(
      relativePath: { eq: "news/2021_09_09_ss_mrgroup/avatar.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 95, placeholder: BLURRED)
      }
    }
    background: file(
      relativePath: { eq: "news/2021_09_09_ss_mrgroup/background.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    backgroundMobile: file(
      relativePath: { eq: "news/2021_09_09_ss_mrgroup/background-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    result: file(
      relativePath: { eq: "news/2021_09_09_ss_mrgroup/image-5.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    logo: file(relativePath: { eq: "news/2021_09_09_ss_mrgroup/logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 237, placeholder: BLURRED)
      }
    }
    logoMobile: file(
      relativePath: { eq: "news/2021_09_09_ss_mrgroup/logo-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 152, placeholder: BLURRED)
      }
    }
  }
`
